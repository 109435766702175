import { render, staticRenderFns } from "./DetailLayout.vue?vue&type=template&id=99b0fefa&scoped=true&"
import script from "./DetailLayout.vue?vue&type=script&lang=js&"
export * from "./DetailLayout.vue?vue&type=script&lang=js&"
import style0 from "./DetailLayout.vue?vue&type=style&index=0&id=99b0fefa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99b0fefa",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLabel } from 'vuetify/lib/components/VLabel';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VAlert,VBadge,VBtn,VCol,VDataTable,VFadeTransition,VIcon,VImg,VLabel,VList,VListItem,VListItemContent,VListItemIcon,VResponsive,VRow,VSheet})
